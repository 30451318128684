import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AppService {

    
    // clientId = '4Pi7G4DnEA40EpL6LteckgYNLaEN0ZZA';
    // clientSecret = 'biDSqFBEa3CURT03';
    // clientId = 'kerQaAoKHGQ60wtnuAXNlhs3AJwrYRHT';
    // clientSecret = 'WCdVUrpBw6IzkPAt';
    // clientId = 'SzDpQDeiRHUlxeZvA4GG1I5SqrkdNbis';
    // clientSecret = 'xCAiX2VJyjpx0x5D';
    clientId = 'ZeW3yebJpH2ksFBkQJb9JSuDxlaAJqOR';
    clientSecret = 'REhrcAsX8IYe0FYG';

    constructor(private http: HttpClient) { }

    authenticateUser() {
        let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        let options = {
            headers: headers
        }
        return this.http.post('https://developer.api.autodesk.com/authentication/v1/authenticate', 'client_id=' + this.clientId + '&client_secret=' + this.clientSecret + '&grant_type=client_credentials&scope=data:read data:write data:create bucket:read bucket:create', options);
    }

    createBucket(token) {
        let header1 = new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json; charset=utf-8' });
        let options1 = {
            headers: header1
        }
        return this.http.post('https://developer.api.autodesk.com/oss/v2/buckets',
            {
                "bucketKey": "test_kt_08_09102020",
                "permissions": [{ "authId": "4Pi7G4DnEA40EpL6LteckgYNLaEN0ZZA", "access": "full" }],
                "policyKey": "persistent"
            }, options1);
    }

    getBucket(token) {
        let header2 = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        let options2 = {
            headers: header2
        }
        return this.http.get('https://developer.api.autodesk.com/oss/v2/buckets/test_kt_01_08102020/details', options2);
    }

    getFiles(token) {
        let header2 = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        let options2 = {
            headers: header2
        }
        return this.http.get('https://developer.api.autodesk.com/oss/v2/buckets/test_kt_08_09102020/objects', options2);
        // test_kt_08_09102020 is a bucket key
    }

    getDetails(token) {
        let header2 = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        let options2 = {
            headers: header2
        }
        return this.http.get('https://developer.api.autodesk.com/oss/v2/buckets/test_kt_08_09102020/objects/Hawa_BIM_House_Revit2018.rvt/details', options2);
        // test_kt_08_09102020 is a bucket key
        // Hawa_BIM_House_Revit2018.rvt is object key
    }

    getManifest(token) {
        let header3 = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        let options3 = {
            headers: header3
        }
        return this.http.get('https://developer.api.autodesk.com/modelderivative/v2/designdata/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dGVzdF9uZXcwNl8wNjEwMjAyMC9yYWNfYmFzaWNfc2FtcGxlX3Byb2plY3QucnZ0/manifest', options3);
    }

    uploadFile(token, file) {
        let header4 = new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/octet-stream', 'Content-Length': file.size });
        let options4 = {
            headers: header4
        }
        return this.http.put('https://developer.api.autodesk.com/oss/v2/buckets/test_kt_08_09102020/objects/' + file.name, file, options4);
    }

    postJobSvf(token, urn) {
        let header4 = new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json; charset=utf-8' });
        let options4 = {
            headers: header4
        }
        return this.http.post('https://developer.api.autodesk.com/modelderivative/v2/designdata/job', {
            "input": {
                "urn": urn,
                "rootFilename": "Hawa_BIM_House_Revit2018.rvt"
            },
            "output": {
                "destination": {
                    "region": "us"
                },
                "formats": [
                {
                    "type": "svf",
                    "views": ["3d", "2d"]
                }]
            }
        }, options4);
    }

}

