import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { AppService } from './app.service';
declare var Autodesk: any;
//declare const THREE: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  public documentId: string;
  public viewerOptions3d: any;
  public token: string = '';
  // public urn: string = 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dGVzdF9rdF8wOF8wOTEwMjAyMC9IYXdhX0JJTV9Ib3VzZV9SZXZpdDIwMTgucnZ0';
  //"dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dGVzdF9rdF8wMl8wOTEwMjAyMC9SQzROT0NQX05MMDIucnZ0";
  public urn: string = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmI5YTI4YjctYTFjMS0xMWViLWFkNjktMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfMjBfMDQucnZ0";
  // public urn: string = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDdmN2IzMjktNWE1MS0xMWViLThlZDAtMDIwMDAwNzUyODQwL01ldGFsY28ucnZ0";
  //public urn: string = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dGVzdF9rdF8wOF8wOTEwMjAyMC8xNl9faGNyMDE1MjRfcmZhLnJmYQ";


  constructor(private appService: AppService) { }

  // public ngOnInit() {
  //   this.appService.authenticateUser().subscribe(data => {
  //     let result: any = data;
  //     this.token = result.access_token;
  //     //this.getManifest(result.access_token);
  //     //this.createBucket(result.access_token);
  //     this.getOtherData();
  //     //this.getBucket(result.access_token);
  //     //this.getFiles();
  //   }, error => {
  //     alert('error.');
  //   });
  //   // this.getOtherData();
  // }

  // uploadFile(event) {
  //   let fileList: FileList = event;

  //   // const formData = new FormData();
  //   // formData.append("fileUpload", fileList[0], fileList[0].name);
  //   this.appService.uploadFile(this.token, fileList[0]).subscribe(data => {
  //     let result = data;
  //   }, error => {
  //     alert('error.');
  //   });
  // }

  // createBucket() {
  //   this.appService.createBucket(this.token).subscribe(data => {
  //     let result: any = data;
  //   }, error => {
  //     alert(error.error.reason);
  //   });
  // }

  // getBucket(access_token) {
  //   this.appService.getBucket(access_token).subscribe(data => {
  //     let result: any = data;
  //   }, error => {
  //     alert('error.');
  //   });
  // }

  // // get all files uploaded to your bucket
  // getFiles() {
  //   this.appService.getFiles(this.token).subscribe(data => {
  //     let result: any = data;
  //     this.urn = btoa(result.items[0].objectId)
  //   }, error => {
  //     alert('error.');
  //   });
  // }

  // // get file details for specific file
  // getDetails() {
  //   this.appService.getDetails(this.token).subscribe(data => {
  //     let result: any = data;
  //   }, error => {
  //     alert('error.');
  //   });
  // }

  // postJobSvf() {
  //   this.appService.postJobSvf(this.token, this.urn).subscribe(data => {
  //     let result: any = data;
  //   }, error => {
  //     alert('error.');
  //   });
  // }

  // getManifest(access_token) {
  //   this.appService.getManifest(access_token).subscribe(data => {
  //     let result: any = data;
  //     this.getOtherData();
  //     // this.getOtherData(result.access_token);
  //   }, error => {
  //     alert('error.');
  //   });
  // }

  // getOtherData() {
  //   let token = this.token;//'eyJhbGciOiJIUzI1NiIsImtpZCI6Imp3dF9zeW1tZXRyaWNfa2V5In0.eyJzY29wZSI6WyJkYXRhOnJlYWQiLCJkYXRhOndyaXRlIiwiZGF0YTpjcmVhdGUiLCJidWNrZXQ6cmVhZCIsImJ1Y2tldDpjcmVhdGUiXSwiY2xpZW50X2lkIjoiNFBpN0c0RG5FQTQwRXBMNkx0ZWNrZ1lOTGFFTjBaWkEiLCJhdWQiOiJodHRwczovL2F1dG9kZXNrLmNvbS9hdWQvand0ZXhwNjAiLCJqdGkiOiJzQ2JTOFRUR0dhR3lFWDBkNHpSM29mZDJGMkp6Vno3emZlcnFvNGg1OFZFZHdKRzgzWmliY1NjMWFTc0tmRTJvIiwiZXhwIjoxNjAyMjQ4NTAxfQ.MAVIz64GwnEf2oh5jsna4eZ6zwZP7T4pkrlwTjAggPw';
  //   let urn = this.urn;//'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dGVzdF9rdF8wNV8wOTEwMjAyMC9jYWxlZmZpLnJ2dA';
  //   // urn = urn.substring(0, urn.length-2);
  //   this.getOtherData2(token, urn);
  // }

  // getOtherData2(token, urn) {
  //   let accesstoken = this.token;
  //   var viewerApp;
  //   var options = {
  //     env: 'AutodeskProduction',
  //     getAccessToken: function (onGetAccessToken) {
  //       var accessToken = token;
  //       //'eyJhbGciOiJIUzI1NiIsImtpZCI6Imp3dF9zeW1tZXRyaWNfa2V5In0.eyJzY29wZSI6WyJkYXRhOnJlYWQiLCJkYXRhOndyaXRlIiwiZGF0YTpjcmVhdGUiLCJidWNrZXQ6cmVhZCIsImJ1Y2tldDpjcmVhdGUiXSwiY2xpZW50X2lkIjoiNFBpN0c0RG5FQTQwRXBMNkx0ZWNrZ1lOTGFFTjBaWkEiLCJhdWQiOiJodHRwczovL2F1dG9kZXNrLmNvbS9hdWQvand0ZXhwNjAiLCJqdGkiOiJzQ2JTOFRUR0dhR3lFWDBkNHpSM29mZDJGMkp6Vno3emZlcnFvNGg1OFZFZHdKRzgzWmliY1NjMWFTc0tmRTJvIiwiZXhwIjoxNjAyMjQ4NTAxfQ.MAVIz64GwnEf2oh5jsna4eZ6zwZP7T4pkrlwTjAggPw';
  //       //this.token;
  //       //'eyJhbGciOiJIUzI1NiIsImtpZCI6Imp3dF9zeW1tZXRyaWNfa2V5In0.eyJzY29wZSI6WyJkYXRhOnJlYWQiLCJkYXRhOndyaXRlIiwiZGF0YTpjcmVhdGUiLCJidWNrZXQ6cmVhZCIsImJ1Y2tldDpjcmVhdGUiXSwiY2xpZW50X2lkIjoiNFBpN0c0RG5FQTQwRXBMNkx0ZWNrZ1lOTGFFTjBaWkEiLCJhdWQiOiJodHRwczovL2F1dG9kZXNrLmNvbS9hdWQvand0ZXhwNjAiLCJqdGkiOiI2clBYVk1Gd3BlOGV4UUZZVWliNkN3S3hvcWV3RUlYcFpwaTZsWk5wM1d4c1lGdFBlTW1pZzdsZUJ5TFZWUk5RIiwiZXhwIjoxNjAyMDYyNDQwfQ.BQs95Q-5CsKSGb1LWkbEnaxIuEzB4VtOLl1r-emRiG4';
  //       var expireTimeSeconds = 60 * 30;
  //       onGetAccessToken(accessToken, expireTimeSeconds);
  //     }

  //   };
  //   let config3d = {
  //     extensions: ['Autodesk.InViewerSearch']
  //   };

  //   var documentId = 'urn:' + urn;
  //   //'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dGVzdF9rdF8wNV8wOTEwMjAyMC9jYWxlZmZpLnJ2dA';
  //   //'urn:' + this.urn;
  //   //'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dGVzdF9uZXcwNl8wNjEwMjAyMC9yYWNfYmFzaWNfc2FtcGxlX3Byb2plY3QucnZ0';
  //   Autodesk.Viewing.Initializer(options, function onInitialized() {
  //     viewerApp = new Autodesk.Viewing.ViewingApplication('MyViewerDiv');
  //     viewerApp.registerViewer(viewerApp.k3D, Autodesk.Viewing.Private.GuiViewer3D, config3d);
  //     viewerApp.loadDocument(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
  //   });

  //   function onDocumentLoadSuccess(doc) {
  //     // We could still make use of Document.getSubItemsWithProperties()
  //     // However, when using a ViewingApplication, we have access to the **bubble** attribute,
  //     // which references the root node of a graph that wraps each object from the Manifest JSON.
  //     var viewables = viewerApp.bubble.search({ 'type': 'geometry' });
  //     if (viewables.length === 0) {
  //       console.error('Document contains no viewables.');
  //       return;
  //     }

  //     // Choose any of the avialble viewables
  //     viewerApp.selectItem(viewables[0].data, onItemLoadSuccess, onItemLoadFail);
  //   }

  //   function onDocumentLoadFailure(viewerErrorCode) {
  //     console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
  //   }

  //   function onItemLoadSuccess(viewer, item) {
  //     console.log('onItemLoadSuccess()!');
  //     console.log(viewer);
  //     console.log(item);

  //     // Congratulations! The viewer is now ready to be used.
  //     console.log('Viewers are equal: ' + (viewer === viewerApp.getCurrentViewer()));
  //   }

  //   function onItemLoadFail(errorCode) {
  //     console.error('onItemLoadFail() - errorCode:' + errorCode);
  //   }
  // }

}
