
<!-- <div class="text-center">
    <img class="ht-50" src="../assets/images/logoskeinbim.png">
    <img class="ht-50" src="../assets/images/logo_ok.png">
</div>
<div id="MyViewerDiv"></div> -->


<!-- <div class="layout">
    <img class="ht-50" src="../assets/images/logoskeinbim.png">
</div> -->

<router-outlet></router-outlet>